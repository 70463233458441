import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [SpinnerMixin],
    data() {
        return {
            offices: [],
            services: [],
            officeId: null,
            serviceId: null
        };
    },
    computed: {
        ...mapGetters({ hasMultipleOffices: "account/hasMultipleOffices" }),
        officeServices() {
            if (this.officeId == null)
                return this.services;
            return this.services.filter(service => service.offices?.some(serviceOffice => serviceOffice.officeId === this.officeId));
        }
    },
    methods: {
        setOfficeId() {
            if (this.officeId == null || this.serviceId == null)
                return;
            const service = this.services.find(service => service.id === this.serviceId);
            if (service != undefined && !service.offices?.some(serviceOffice => serviceOffice.officeId == this.officeId))
                this.serviceId = null;
        },
        search() {
            this.$emit("search", {
                officeId: this.officeId,
                serviceId: this.serviceId
            });
        }
    },
    async mounted() {
        //this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll");
            this.offices.unshift({
                id: null,
                name: this.$t('offices.all')
            });
            this.services = await this.$store.dispatch("service/getAll", {
                load: false
            });
            this.services = this.services.filter(service => service.deletedAt == undefined);
            this.services.unshift({
                id: null,
                name: this.$t('services.all')
            });
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
        finally {
            //this.hideSpinner()
        }
    }
});
