import Vue from "vue";
import { GridPlugin, ForeignKey, Page, Filter, Sort, Toolbar, Search, Edit, DetailRow, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager, Query } from "@syncfusion/ej2-data";
import SpinnerMixin from "@/mixins/spinner";
import TitleMixin from "@/mixins/title";
import GridMixin from "@/mixins/syncfusion/grid";
import SearchToolbar from "@/components/Templates/Employee/SearchToolbar.vue";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    components: {
        "search-toolbar": SearchToolbar
    },
    mixins: [TitleMixin, SpinnerMixin, GridMixin],
    data() {
        return {
            //employeeRoles: [] as Array<EmployeeRoleModel>,
            offices: [],
            services: [],
            title: this.$t("employees.employees"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "employees/grid-data",
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "employees/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "employees/grid-edit",
                removeUrl: process.env.VUE_APP_API_BASE_URL + "employees/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            sortOptions: {
                columns: [
                    { field: "Surname", direction: "Ascending" },
                    { field: "Name", direction: "Ascending" }
                ]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            searchOptions: {
                fields: ["Name", "Surname", "FiscalCode", "VatNumber", "Email"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "DailyWorkAvailabilityAndAbsence",
                    title: this.$t('dailyWorkAvailability.title'),
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-icons e-date-time"
                    }
                },
                {
                    type: "WorkHoursEdit",
                    title: this.$t('employees.editWorkTime'),
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-icons e-work-hours"
                    }
                },
                {
                    type: "Attachments",
                    title: "Allegati",
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-folder-open e-icons"
                    }
                },
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ],
            childGrid: {
                queryString: 'EmployeeId',
                columns: [
                    { field: 'EmployeeId', headerText: this.$t('shared.id'), width: 0, visible: false },
                    { field: 'OfficeId', headerText: this.$t('shared.id'), width: 0, visible: false },
                    { field: 'OfficeName', headerText: this.$t('offices.office'), width: 150, visible: this.$store.getters["account/hasMultipleOffices"] },
                    { field: 'ServiceId', headerText: this.$t('shared.id'), width: 0, visible: false },
                    { field: 'ServiceName', headerText: this.$t('services.service'), width: 150 },
                    { field: 'HourlyCost', headerText: this.$t('employees.hourlyCost'), width: 100, format: "C2" }
                ],
                load: function () {
                    const grid = this;
                    grid.parentDetails.parentKeyFieldValue = grid.parentDetails.parentRowData['Id'];
                }
            }
        };
    },
    provide: {
        grid: [CommandColumn, ForeignKey, Page, Filter, Sort, Toolbar, Search, Edit, DetailRow]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        search(args) {
            const grid = this.$refs["employees-grid"];
            const gridInstance = grid.ej2Instances;
            // Build query
            const query = new Query();
            if (args.officeId != null)
                query.addParams("officeId", args.officeId.toString());
            if (args.serviceId != null)
                query.addParams("serviceId", args.serviceId.toString());
            // Set the query and refresh the grid
            gridInstance.query = query;
            grid.refresh();
        },
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push("/employees/create");
                return;
            }
        },
        commandClick(args) {
            if (args.commandColumn && args.commandColumn.type === "Custom_Edit") {
                this.$router.push("/employees/" + args.rowData.Id + "/edit");
                return;
            }
            if (args.commandColumn && args.commandColumn.type === "WorkHoursEdit") {
                this.$router.push("/employees/" + args.rowData.Id + "/work-weeks");
                return;
            }
            if (args.commandColumn.type == "Attachments") {
                this.$router.push("/employees/" + args.rowData.Id + "/attachments");
                return;
            }
            if (args.commandColumn && args.commandColumn.type === "DailyWorkAvailabilityAndAbsence") {
                this.$router.push("/employees/" + args.rowData.Id + "/daily-work-availabilities");
                return;
            }
        },
        onDetailDataBound(args) {
            const grid = args.detailElement.querySelector('.e-grid');
            const gridInstance = grid.ej2_instances[0];
            // Set missing data
            if (args.data.Services != null) {
                for (const employeeService of args.data.Services) {
                    employeeService.EmployeeId = args.data.Id;
                    const office = this.offices.find(office => office.id === employeeService.OfficeId);
                    if (office != undefined)
                        employeeService.OfficeName = office.name;
                    const service = this.services.find(service => service.id === employeeService.ServiceId);
                    if (service != undefined)
                        employeeService.ServiceName = service.name;
                }
            }
            // Set child grid data source
            gridInstance.dataSource = args.data.Services;
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("employees.delete.toastSuccessTitle"), this.$t("employees.delete.toastSuccessContent", {
                    name: `${args.data[0].Name} ${args.data[0].Surname}`
                }));
            }
        }
    },
    /*
    async created(): Promise<void> {
      try {
        this.employeeRoles = await this.$store.dispatch("employeeRole/getAll");
      } catch (errors) {
        this.$toast.showDangerToast(
          this.$t("shared.toastFailureTitle") as string,
          this.$t("shared.toastFailureContent", {
            error: errors[0].message
          }) as string
        );
      }
    }
    */
    async mounted() {
        this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll");
            this.services = await this.$store.dispatch("service/getAll", {
                load: true
            });
        }
        finally {
            this.hideSpinner();
        }
    }
});
